import { useEffect, useState } from 'react'
import { Avatar, Box, Button, Divider, FormControl, TextField } from '@mui/material'
import AuthService from 'api/services/auth.service'
import UserService from 'api/services/user.service'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import SideModal from 'components/SideModal/SideModal'
import InternalUser from 'models/InternalUser'
import Styles from './_Profile.module.scss'

const Profile = () => {
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' })
  const [user, setUser] = useState(new InternalUser())
  const [showSideModal, setShowSideModal] = useState(false)

  const getUser = async () => {
    try {
      const resp = await AuthService.getCurrentUser()
      setUser(resp)
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Something went wrong while retrieving Profile, try again later'
      })
    }
  }

  const getCapitalLetters = user => {
    return `${user.name.charAt(0)}${user.last_name?.charAt(0)}`
  }

  const handleFormChange = (key, value) => {
    setUser(prevState => ({ ...prevState, [key]: value }))
  }

  const handleSaveUser = async () => {
    try {
      await UserService.update(user.id, user)
      setShowSideModal(false)
      setAlert({ open: true, severity: 'error', message: 'Profile updated with success' })
      getUser()
    } catch (error) {
      setShowSideModal(false)
      setAlert({ open: true, severity: 'error', message: 'Something went wrong while saving Profile, try again later' })
    }
  }

  useEffect(() => {
    getUser()
  }, [])
  return (
    <>
      <h1>Profile</h1>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '30rem' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '1.5rem' }}>
            <Avatar sx={{ width: 72, height: 72, margin: 1 }}>{getCapitalLetters(user)}</Avatar>
            <b>
              {user.name} {user.last_name}
            </b>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                sx={{ fontSize: '.65rem' }}
                onClick={() => setShowSideModal(true)}>
                Editar
              </Button>
            </Box>
            <Divider sx={{ margin: '1rem 0', borderWidth: '2px' }} />
            <Box className={Styles.profile__item}>
              <small>Name</small>
              <span>
                {user.name} {user.last_name}
              </span>
            </Box>
            <Divider sx={{ margin: '.75rem 0' }} />
            <Box className={Styles.profile__item}>
              <small>Email</small>
              <span>{user.email}</span>
            </Box>
            <Divider sx={{ margin: '.75rem 0' }} />
            <Box className={Styles.profile__item}>
              <small>Phone</small>
              <span>{user.phone}</span>
            </Box>
            <Divider sx={{ margin: '.75rem 0' }} />
          </Box>
        </Box>
      </Box>
      <SideModal
        open={showSideModal}
        title="Update profile"
        onCancel={() => setShowSideModal(false)}
        onConfirm={handleSaveUser}>
        {user && (
          <>
            <FormControl className={Styles.input}>
              <TextField
                label="Name"
                size="small"
                variant="outlined"
                value={user.name}
                onChange={e => handleFormChange('name', e.target.value)}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                label="Last Name"
                size="small"
                variant="outlined"
                value={user.last_name}
                onChange={e => handleFormChange('last_name', e.target.value)}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                label="Email"
                size="small"
                variant="outlined"
                value={user.email}
                onChange={e => handleFormChange('email', e.target.value)}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                label="Phone"
                size="small"
                variant="outlined"
                value={user.phone}
                onChange={e => handleFormChange('phone', e.target.value)}
              />
            </FormControl>
          </>
        )}
      </SideModal>
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
    </>
  )
}

export default Profile
