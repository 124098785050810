export class InternalUser {
  id
  name
  last_name
  email
  internal_user_role_id
  internal_user_role
  password
  password_confirmation
  created_at
  updated_at
  deleted_at

  constructor() {
    this.id = 0
    this.name = ''
    this.last_name = ''
    this.email = ''
    this.phone = ''
    this.internal_user_role_id = 2
    this.internal_user_role = { id: 1, name: 'Administrador' }
    this.created_at = new Date()
    this.updated_at = new Date()
  }
}

export default InternalUser
