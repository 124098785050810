import axios from '../axios'

class ModuleService {
  static async store(params) {
    const resp = await axios.post('/modules', params)
    return resp.data
  }

  static async findAll() {
    const resp = await axios.get('/modules')
    return resp.data
  }

  static async update(id, params) {
    const resp = await axios.put(`/modules/${id}`, { ...params, _method: 'put' })
    return resp.data
  }

  static async delete(id) {
    const resp = await axios.delete(`/modules/${id}`)
    return resp.data
  }

  static async restore(id) {
    const resp = await axios.get(`/modules/restore/${id}`)
    return resp.data
  }
}

export default ModuleService
