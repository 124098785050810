import PropTypes from 'prop-types'
import { Backdrop, Box, Button, CircularProgress } from '@mui/material'
import Styles from './_SideModal.module.scss'

const SideModal = ({ open, children, title, loading = false, onConfirmText, onCancelText, onConfirm, onCancel }) => {
  return (
    <Backdrop open={open} sx={{ justifyContent: 'end' }}>
      <Box className={Styles.sidemodal}>
        <Box className={Styles.sidemodal__header}>{title || 'Creation form'}</Box>
        <Box className={Styles.sidemodal__body}>
          <Box sx={{ padding: '1.5rem' }}>{children}</Box>
        </Box>
        <Box className={Styles.sidemodal__footer}>
          <Button
            fullWidth={true}
            variant="outlined"
            sx={{ marginRight: '.5rem' }}
            disabled={loading}
            onClick={onCancel}>
            {onCancelText || 'Cancel'}
          </Button>
          <Button
            fullWidth={true}
            variant="contained"
            sx={{ marginLeft: '.5rem' }}
            disabled={loading}
            onClick={() => onConfirm()}>
            {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : onConfirmText || 'Save'}
          </Button>
        </Box>
      </Box>
    </Backdrop>
  )
}

SideModal.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.element,
  title: PropTypes.string,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onCancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onConfirmText: PropTypes.string
}

export default SideModal
