import axios from '../axios'

class PlanService {
  static async store(params) {
    const resp = await axios.post('/plans', params)
    return resp.data
  }

  static async findAll() {
    const resp = await axios.get('/plans')
    return resp.data
  }

  static async update(id, params) {
    const resp = await axios.put(`/plans/${id}`, { ...params, _method: 'put' })
    return resp.data
  }

  static async delete(id) {
    const resp = await axios.delete(`/plans/${id}`)
    return resp.data
  }

  static async restore(id) {
    const resp = await axios.get(`/plans/restore/${id}`)
    return resp.data
  }
}

export default PlanService
