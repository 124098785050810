import axios from '../axios'

class AuthService {
  static async login(email, password) {
    const resp = await axios.post('/internal/login', { email, password })
    return resp.data
  }

  static async logout() {
    const resp = await axios.get('/internal/logout')
    return resp
  }

  static async getCurrentUser() {
    const resp = await axios.get('/internal/user')
    return resp.data
  }
}

export default AuthService
