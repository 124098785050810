import { useEffect, useState } from 'react'
import { Box, Button, FormControl, TextField } from '@mui/material'
import ModuleService from 'api/services/module.service'
import DataTable from 'components/DataTable/DataTable'
import DeleteModal from 'components/DeleteModal/DeleteModal'
import SideModal from 'components/SideModal/SideModal'
import Module from 'models/Module'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import Styles from './_Modules.module.scss'

const Modules = () => {
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' })
  const [modulesList, setModulesList] = useState([])
  const [showSideModal, setShowSideModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [actionType, setActionType] = useState()
  const [selectedModule, setSelectedModule] = useState(new Module())

  const handleActions = (type, value) => {
    setSelectedModule(value)
    setActionType(type)
    if (type === 'edit') {
      setShowSideModal(true)
    } else {
      setShowDeleteModal(true)
    }
  }

  const headers = [
    {
      header: 'Name',
      key: 'name',
      cellType: 'text'
    },
    {
      header: 'Description',
      key: 'description',
      cellType: 'text'
    },
    {
      header: 'API',
      key: 'api_url',
      cellType: 'text'
    },
    {
      header: 'Token',
      key: 'token',
      cellType: 'text'
    },
    {
      header: 'Actions',
      key: 'actions',
      cellType: 'action_buttons',
      action: handleActions
    }
  ]

  const orderData = data => {
    const orderedData = data.map(element => {
      const row = headers.map(header => {
        const value = element[header.key]
        const obj = {
          cellType: header.cellType,
          key: header.key,
          value: value,
          action: (...values) => header.action(...values, element)
        }

        return obj
      })
      return row
    })
    return orderedData
  }

  const getModules = async () => {
    try {
      const modules = await ModuleService.findAll()
      setModulesList(orderData(modules))
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Something went wrong while retrieving Modules, try again later'
      })
    }
  }

  const handleCreateModule = () => {
    setActionType('create')
    setShowSideModal(true)
    setSelectedModule(new Module())
  }

  const handleFormChange = (key, value) => {
    setSelectedModule(prevState => ({ ...prevState, [key]: value }))
  }

  const handleSaveModule = async () => {
    try {
      actionType === 'create'
        ? await ModuleService.store(selectedModule)
        : await ModuleService.update(selectedModule.id, selectedModule)
      setShowSideModal(false)
      setAlert({ open: true, severity: 'success', message: 'Module saved with success' })
      getModules()
    } catch (error) {
      setShowSideModal(false)
      setAlert({ open: true, severity: 'error', message: 'Something went wrong while saving Module, try again later' })
    }
  }

  const handleDeleteModule = async () => {
    try {
      await ModuleService.delete(selectedModule.id)
      setShowDeleteModal(false)
      setAlert({ open: true, severity: 'success', message: 'Module deleted with success' })
      getModules()
    } catch (error) {
      setShowDeleteModal(false)
      setAlert({
        open: true,
        severity: 'error',
        message: 'Something went wrong while deleting Module, try again later'
      })
    }
  }

  useEffect(() => {
    getModules()
  }, [])

  return (
    <>
      <h1>Modules</h1>
      <Box sx={{ margin: '2rem 0', display: 'flex', justifyContent: 'space-between' }}>
        <TextField size="small" placeholder="Search" />
        <Button variant="contained" color="primary" size="small" onClick={handleCreateModule}>
          Create module
        </Button>
      </Box>
      <Box sx={{ margin: '2rem 0' }}>
        <DataTable headers={headers} data={modulesList} />
      </Box>
      <SideModal
        title={`${actionType === 'create' ? 'Create' : 'Update'} Module`}
        open={showSideModal}
        onCancel={() => setShowSideModal(false)}
        onConfirm={handleSaveModule}>
        {selectedModule && (
          <>
            <FormControl className={Styles.input}>
              <TextField
                label="Name"
                size="small"
                variant="outlined"
                value={selectedModule.name}
                onChange={e => handleFormChange('name', e.target.value)}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                label="Description"
                size="small"
                variant="outlined"
                value={selectedModule.description}
                onChange={e => handleFormChange('description', e.target.value)}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                label="API URL"
                size="small"
                variant="outlined"
                value={selectedModule.api_url}
                onChange={e => handleFormChange('api_url', e.target.value)}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                label="Token"
                size="small"
                variant="outlined"
                value={selectedModule.token}
                onChange={e => handleFormChange('token', e.target.value)}
              />
            </FormControl>
          </>
        )}
      </SideModal>
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
      <DeleteModal
        element={selectedModule}
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteModule}
      />
    </>
  )
}

export default Modules
