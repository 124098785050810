import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Avatar, Box, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material'
import Settings from '@mui/icons-material/Settings'
import Person from '@mui/icons-material/Person'
import Logout from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import AuthService from 'api/services/auth.service'
import Loader from 'components/Loader/Loader'
import Styles from './_Header.module.scss'

const Header = ({ onMenuClick }) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const goto = url => {
    navigate(url)
    handleClose()
  }

  const handleLogout = async () => {
    setLoading(true)
    try {
      await AuthService.logout()
      navigate('login')
    } catch (error) {
      navigate('login')
    }
  }

  const handleSidebar = () => {
    onMenuClick()
  }

  useEffect(() => {
    if (localStorage.getItem('user')) {
      setUser(JSON.parse(localStorage.getItem('user')))
    }
  }, [])

  return (
    <>
      <Box className={Styles.header}>
        <Box>
          <IconButton onClick={handleSidebar}>
            <MenuIcon />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            disableRipple
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <Avatar sx={{ width: 32, height: 32, margin: 1, textTransform: 'uppercase' }}>
              {user ? `${user.name.charAt(0)}${user.last_name?.charAt(0)}` : ''}
            </Avatar>
            {user ? `${user.name} ${user.last_name?.charAt(0)}` : ''}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem onClick={() => goto('profile')}>
              <ListItemIcon>
                <Person fontSize="small" />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      {loading && <Loader />}
    </>
  )
}

Header.propTypes = {
  onMenuClick: PropTypes.func
}

export default Header
