export class Module {
  id
  name
  description
  api_url
  token
  created_at
  updated_at
  deleted_at

  constructor() {
    this.name = ''
    this.description = ''
    this.api_url = ''
    this.token = ''
    this.created_at = new Date()
    this.updated_at = new Date()
  }
}

export default Module
