import { createTheme } from '@mui/material'

const theme = createTheme({
  typography: {
    fontFamily: 'Urbanist'
  },
  palette: {
    primary: {
      main: '#002069'
    },
    neutral: {
      main: '#eceff1'
    }
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0
        }
      }
    }
  }
})

export default theme
