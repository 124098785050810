import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, ListItemIcon } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import DnsIcon from '@mui/icons-material/Dns'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import Styles from './_SideMenu.module.scss'

const SideMenu = ({ show }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  return (
    <Box className={`${Styles.sidemenu} ${!show && Styles.hide}`}>
      <Box className={Styles.sidemenu__icon}>
        <img src="Logo_SideMenu.png" alt="" />
      </Box>
      <hr />

      <Box className={Styles.menu}>
        <Box className={`${Styles.menu__item} ${pathname === '/' && Styles.selected}`} onClick={() => navigate('/')}>
          <ListItemIcon className={Styles.menu__item_icon}>
            <HomeIcon color="neutral" />
          </ListItemIcon>
          Home
        </Box>
        <Box
          className={`${Styles.menu__item} ${pathname.includes('internalUsers') && Styles.selected}`}
          onClick={() => navigate('internalUsers')}>
          <ListItemIcon className={Styles.menu__item_icon}>
            <PeopleAltIcon color="neutral" />
          </ListItemIcon>
          Internal Users
        </Box>
        <Box
          className={`${Styles.menu__item} ${pathname.includes('packages') && Styles.selected}`}
          onClick={() => navigate('packages')}>
          <ListItemIcon className={Styles.menu__item_icon}>
            <DnsIcon color="neutral" />
          </ListItemIcon>
          Packages
        </Box>
        <Box
          className={`${Styles.menu__item} ${pathname.includes('modules') && Styles.selected}`}
          onClick={() => navigate('modules')}>
          <ListItemIcon className={Styles.menu__item_icon}>
            <ViewModuleIcon color="neutral" />
          </ListItemIcon>
          Modules
        </Box>
      </Box>
    </Box>
  )
}

SideMenu.propTypes = {
  show: PropTypes.bool
}

export default SideMenu
