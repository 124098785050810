import React from 'react'
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute'
import Layout from 'views/Layout/Layout'
import InternalUsers from 'views/InternalUsers/InternalUsers'
import Packages from 'views/Packages/Packages'
import Profile from 'views/Profile/Profile'
import Login from 'views/Login/Login'
import Modules from 'views/Modules/Modules'
import Register from 'views/Register/Register'
import NotFound from 'views/NotFound/NotFound'

const routes = [
  {
    path: 'login',
    element: <Login />
  },
  {
    path: 'register',
    element: <Register />
  },
  {
    path: '',
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'internalUsers',
        element: <InternalUsers />
      },
      {
        path: 'modules',
        element: <Modules />
      },
      {
        path: 'packages',
        element: <Packages />
      },
      {
        path: 'profile',
        element: <Profile />
      }
    ]
  },
  {
    path: '*',
    element: (
      <ProtectedRoute>
        <NotFound />
      </ProtectedRoute>
    )
  }
]

export default routes
