import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import Header from './components/Header/Header'
import SideMenu from './components/SideMenu/SideMenu'
import Styles from './_Layout.module.scss'

const Layout = () => {
  const [showSidebar, setShowSidebar] = useState(true)

  return (
    <Box className={Styles.wrapper}>
      <SideMenu show={showSidebar} />
      <Box className={`${Styles.layout} ${!showSidebar && Styles.hide_sidebar}`}>
        <Header onMenuClick={() => setShowSidebar(!showSidebar)} />
        <Box className={Styles.layout__content}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default Layout
