import * as Yup from 'yup'

export const internalUserCreationFormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  password_confirmation: Yup.string()
    .required('Password is required')
    .oneOf([Yup.ref('password')], 'Password confirmation does not match')
})

export const internalUserUpdateFormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required')
})
