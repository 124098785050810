import { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, IconButton, TextField } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ModuleService from 'api/services/module.service'
import PlanService from 'api/services/plan.service'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import DeleteModal from 'components/DeleteModal/DeleteModal'
import SideModal from 'components/SideModal/SideModal'
import Plan from 'models/Plan'
import Styles from './_Packages.module.scss'

const Packages = () => {
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' })
  const [showSideModal, setShowSideModal] = useState(false)
  const [modules, setModules] = useState([])
  const [plans, setPlans] = useState([])
  const [plan, setPlan] = useState(new Plan())
  const [actionType, setActionType] = useState()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const getPlans = async () => {
    try {
      const resp = await PlanService.findAll()
      setPlans(resp)
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Something went wrong while retrieving Plans, try again later'
      })
    }
  }

  const getModules = async () => {
    try {
      const resp = await ModuleService.findAll()
      setModules(resp)
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Something went wrong while retrieving Modules, try again later'
      })
    }
  }

  const handleCreatePlan = () => {
    setActionType('create')
    setPlan(new Plan())
    setShowSideModal(true)
  }

  const handleCardClick = plan => {
    setActionType('edit')
    setPlan(plan)
    setShowSideModal(true)
  }

  const handleFormChange = (key, value) => {
    setPlan(prevState => ({ ...prevState, [key]: value }))
  }

  const handleRemoveModule = index => {
    const list = plan.modules
    list.splice(index, 1)
    setPlan(prevState => ({ ...prevState, modules: list }))
  }

  const handleSavePlan = async () => {
    try {
      actionType === 'create' ? await PlanService.store(plan) : await PlanService.update(plan.id, plan)
      setShowSideModal(false)
      setAlert({ open: true, severity: 'success', message: 'Plan saved with success' })
      getPlans()
    } catch (error) {
      setShowSideModal(false)
      setAlert({ open: true, severity: 'error', message: 'Something went wrong while saving Plan, try again later' })
    }
  }

  const handleDeletePlan = async () => {
    try {
      await PlanService.delete(plan.id)
      setShowDeleteModal(false)
      setAlert({ open: true, severity: 'success', message: 'Plan deleted with success' })
      getPlans()
    } catch (error) {
      setShowDeleteModal(false)
      setAlert({ open: true, severity: 'error', message: 'Something went wrong while saving Plan, try again later' })
    }
  }

  useEffect(() => {
    getPlans()
    getModules()
  }, [])

  return (
    <>
      <h1>Packages</h1>
      <Box sx={{ margin: '2rem 0', display: 'flex', justifyContent: 'space-between' }}>
        <TextField size="small" placeholder="Search" />
        <Button variant="contained" color="primary" size="small" onClick={handleCreatePlan}>
          Create package
        </Button>
      </Box>
      <Box sx={{ margin: '2rem 0' }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {plans.length > 0 &&
            plans.map((item, index) => {
              return (
                <Box key={index} className={Styles.card} onClick={() => handleCardClick(item)}>
                  <Box className={Styles.card__header}>
                    <Box className={Styles.card__header__title}>{item.name}</Box>
                    <Box className={Styles.card__header__subtitle}>${item.price}</Box>
                  </Box>
                  <Box className={Styles.card__body}>{item.description}</Box>
                </Box>
              )
            })}
        </Box>
      </Box>
      <SideModal
        title={`${actionType === 'create' ? 'Create' : 'Update'} Package`}
        open={showSideModal}
        onCancel={() => setShowSideModal(false)}
        onConfirm={handleSavePlan}>
        <>
          <FormControl className={Styles.input}>
            <TextField
              label="Name"
              size="small"
              variant="outlined"
              value={plan.name}
              onChange={e => handleFormChange('name', e.target.value)}
            />
          </FormControl>
          <FormControl className={Styles.input}>
            <TextField
              label="Description"
              size="small"
              variant="outlined"
              value={plan.description}
              onChange={e => handleFormChange('description', e.target.value)}
            />
          </FormControl>
          <FormControl className={Styles.input}>
            <TextField
              label="Price"
              type="number"
              size="small"
              variant="outlined"
              value={plan.price}
              onChange={e => handleFormChange('price', e.target.value)}
            />
          </FormControl>
          <FormControl className={Styles.input}>
            <TextField
              label="Slug"
              size="small"
              variant="outlined"
              value={plan.slug}
              onChange={e => handleFormChange('slug', e.target.value)}
            />
          </FormControl>
          <FormControl className={Styles.input}>
            <TextField
              label="Stripe Plan"
              size="small"
              variant="outlined"
              value={plan.stripe_plan}
              onChange={e => handleFormChange('stripe_plan', e.target.value)}
            />
          </FormControl>
          <Divider sx={{ marginBottom: '1rem' }} />
          <h2 style={{ marginBottom: '1rem' }}>Modules</h2>
          <FormControl className={Styles.input}>
            <Autocomplete
              multiple
              size="small"
              options={modules}
              value={plan.modules}
              renderTags={() => {}}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.name}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )
              }}
              renderInput={params => <TextField {...params} label="Modules" placeholder="Search a module" />}
              onChange={(e, value) => handleFormChange('modules', value)}
            />
          </FormControl>
          {plan.modules.length > 0 ? (
            <FormControl className={Styles.input}>
              <Box className={Styles.module__container}>
                {plan.modules.map((item, index) => {
                  return (
                    <Box key={index} className={Styles.module__item}>
                      <Box className={Styles.module__item_body}>
                        <b>{item.name}</b>
                        <small>{item.api_url}</small>
                      </Box>
                      <IconButton onClick={() => handleRemoveModule(index)}>
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </Box>
                  )
                })}
              </Box>
            </FormControl>
          ) : (
            <small>No hay módulos seleccionados</small>
          )}
        </>
      </SideModal>
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
      <DeleteModal
        element={plan}
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDeletePlan}
      />
    </>
  )
}

export default Packages
