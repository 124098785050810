import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import Paper from '@mui/material/Paper'
import PropTypes from 'prop-types'
import { IconButton } from '@mui/material'

const DrawCell = ({ item, index }) => {
  const cellTypes = {
    action_buttons: (item, index) => {
      return (
        <TableCell key={index} component="th" scope="row">
          <IconButton onClick={() => item.action('edit')}>
            <EditOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => item.action('delete')}>
            <DeleteOutlinedIcon />
          </IconButton>
        </TableCell>
      )
    },
    text: (item, index) => {
      return (
        <TableCell key={index} component="th" scope="row">
          {item.value}
        </TableCell>
      )
    }
  }

  const getCell = cellTypes[item.cellType]
  return getCell(item, index)
}

const DataTable = ({ headers = [], data = [] }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => {
              return (
                <TableCell key={index}>
                  <b>{header.header}</b>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {row.map((item, i) => (
                <DrawCell item={item} index={i} key={i} />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

DataTable.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array
}

export default DataTable
