import axios from '../axios'

class UserService {
  static async store(params) {
    const resp = await axios.post('/internal_users', params)
    return resp.data
  }

  static async findAll() {
    const resp = await axios.get('/internal_users')
    return resp.data
  }

  static async update(id, params) {
    const resp = await axios.put(`/internal_users/${id}`, { ...params, _method: 'put' })
    return resp.data
  }

  static async delete(id) {
    const resp = await axios.delete(`/internal_users/${id}`)
    return resp.data
  }

  static async restore(id) {
    const resp = await axios.get(`/internal_users/restore/${id}`)
    return resp.data
  }
}

export default UserService
