import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import UserService from 'api/services/user.service'
import Alert from 'components/AlertPopup/AlertPopup'
import Loader from 'components/Loader/Loader'
import LoaderBackground from 'components/LoaderBackground/LoaderBackground'
import InternalUser from 'models/InternalUser'
import { internalUserCreationFormSchema } from 'utils/schemas/internalUserFormSchema'
import Styles from './_Register.module.scss'

const Register = () => {
  const navigate = useNavigate()
  const [userForm, setUserForm] = useState(new InternalUser())
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' })
  const [loading, setLoading] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)

  const handleRegister = async user => {
    try {
      await UserService.store(user)
      setShowLoader(true)
      setAlert({ open: true, severity: 'success', message: 'Successfully registered. Redirecting' })
      setTimeout(() => {
        navigate('/login')
      }, 2000)
    } catch (error) {
      setLoading(false)
      const message = error.response.data.error.includes('The email has already been taken')
        ? 'Ya existe una cuenta con ese correo'
        : 'No se pudo completar el registro, inténtalo más tarde'
      setAlert({ severity: 'error', open: true, message: message })
    }
  }

  const { errors, touched, values, handleBlur, handleSubmit, handleChange } = useFormik({
    initialValues: userForm,
    enableReinitialize: true,
    validationSchema: internalUserCreationFormSchema,
    onSubmit: vals => {
      setLoading(true)
      setUserForm(vals)
      handleRegister(vals)
    }
  })

  useEffect(() => {
    setLoading(false)
    setAlert({ open: false, severity: '', message: '' })
  }, [])

  return (
    <>
      <LoaderBackground />
      <Box className={Styles.layout}>
        <Box className={Styles.register__card}>
          <Box className={Styles.register__card__img}>
            <img src="Logo_SideMenu.png" alt="" />
          </Box>
          <Box className={Styles.register__card__body}>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <FormControl className={Styles.input}>
                  <TextField
                    name="name"
                    label="Name"
                    size="small"
                    variant="outlined"
                    value={values.name}
                    error={Boolean(touched?.name && errors.name)}
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12}>
                <FormControl className={Styles.input}>
                  <TextField
                    name="last_name"
                    label="Last Name"
                    size="small"
                    variant="outlined"
                    value={values.last_name}
                    error={Boolean(touched?.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12}>
                <FormControl className={Styles.input}>
                  <TextField
                    name="email"
                    label="Email"
                    size="small"
                    variant="outlined"
                    value={values.email}
                    error={Boolean(touched?.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12}>
                <FormControl className={Styles.input}>
                  <TextField
                    name="phone"
                    label="Phone"
                    size="small"
                    variant="outlined"
                    value={values.phone}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12}>
                <FormControl className={Styles.input}>
                  <TextField
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    size="small"
                    variant="outlined"
                    value={values.password}
                    error={Boolean(touched?.password && errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12}>
                <FormControl className={Styles.input}>
                  <TextField
                    name="password_confirmation"
                    type={showPasswordConfirmation ? 'text' : 'password'}
                    label="Confirm Password"
                    size="small"
                    variant="outlined"
                    value={values.password_confirmation}
                    error={Boolean(touched?.password_confirmation && errors.password_confirmation)}
                    helperText={touched.password_confirmation && errors.password_confirmation}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end">
                            {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box className={Styles.register__card__footer}>
            <Button
              disabled={loading}
              sx={{ marginBottom: '1rem', width: '10rem' }}
              variant="contained"
              color="primary"
              onClick={handleSubmit}>
              {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : 'Register'}
            </Button>
            <span>
              Already have an account?{' '}
              <span className={Styles.link} onClick={() => navigate('/login')}>
                Login here
              </span>
            </span>
          </Box>
        </Box>
      </Box>
      {showLoader && <Loader />}
      <Alert
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
    </>
  )
}

export default Register
