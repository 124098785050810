import { Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'

const DeleteModal = ({ element, open, loading = false, onConfirm, onCancel }) => {
  return (
    <Dialog open={open} keepMounted aria-describedby="alert-dialog-slide-description">
      <DialogTitle>
        <h3>Are you sure you want to delete:</h3>
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <h2 style={{ textAlign: 'center' }}>
          <b>{element.name}</b>
        </h2>
        <br />
        <span style={{ textAlign: 'center' }}>You won't be able to revert this action</span>
      </DialogContent>
      <DialogActions>
        <Button sx={{ width: '7rem' }} disabled={loading} variant="outlined" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button sx={{ width: '7rem' }} disabled={loading} variant="contained" onClick={() => onConfirm()}>
          {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  element: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

export default DeleteModal
