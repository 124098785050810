import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Box, Button, Divider, FormControl, IconButton, InputAdornment, MenuItem, TextField } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import UserService from 'api/services/user.service'
import DataTable from 'components/DataTable/DataTable'
import DeleteModal from 'components/DeleteModal/DeleteModal'
import SideModal from 'components/SideModal/SideModal'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import InternalUser from 'models/InternalUser'
import { userRoles } from 'utils/constants'
import { internalUserCreationFormSchema, internalUserUpdateFormSchema } from 'utils/schemas/internalUserFormSchema'
import Styles from './_InternalUsers.module.scss'

const InternalUsers = () => {
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' })
  const [actionType, setActionType] = useState()
  const [loading, setLoading] = useState(false)
  const [showSideModal, setShowSideModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const [selectedUser, setSelectedUser] = useState(new InternalUser())
  const [usersList, setUsersList] = useState([])

  const handleActions = (type, value) => {
    setSelectedUser(value)
    setActionType(type)
    if (type === 'edit') {
      setShowSideModal(true)
    } else {
      setShowDeleteModal(true)
    }
  }

  const headers = [
    {
      header: 'Email',
      key: 'email',
      cellType: 'text'
    },
    {
      header: 'Name',
      key: 'name',
      cellType: 'text'
    },
    {
      header: 'Last name',
      key: 'last_name',
      cellType: 'text'
    },
    {
      header: 'Phone',
      key: 'phone',
      cellType: 'text'
    },
    {
      header: 'Role',
      key: 'internal_user_role',
      cellType: 'text'
    },
    {
      header: 'Actions',
      key: 'actions',
      cellType: 'action_buttons',
      action: handleActions
    }
  ]

  const orderData = data => {
    const orderedData = data.map(element => {
      const row = headers.map(header => {
        let value = element[header.key]
        if (header.key === 'internal_user_role') {
          value = element[header.key].name
        }
        const obj = {
          cellType: header.cellType,
          key: header.key,
          value: value,
          action: (...values) => header.action(...values, element)
        }

        return obj
      })
      return row
    })
    return orderedData
  }

  const getUsers = async () => {
    try {
      const users = await UserService.findAll()
      setUsersList(orderData(users))
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Something went wrong while retrieving Users, try again later'
      })
    }
  }

  const handleSaveUser = async user => {
    try {
      actionType === 'create' ? await UserService.store(user) : await UserService.update(user.id, user)
      setLoading(false)
      setShowSideModal(false)
      setAlert({ open: true, severity: 'success', message: 'User saved with success' })
      getUsers()
    } catch (error) {
      setLoading(false)
      setShowSideModal(false)
      setAlert({ open: true, severity: 'error', message: 'Something went wrong while saving User, try again later' })
    }
  }

  const handleCreateUser = () => {
    setActionType('create')
    setShowSideModal(true)
    setSelectedUser(new InternalUser())
  }

  const handleDeleteUser = async () => {
    setLoading(true)
    try {
      await UserService.delete(selectedUser.id)
      setLoading(false)
      setShowDeleteModal(false)
      setAlert({ open: true, severity: 'success', message: 'User deleted with success' })
      getUsers()
    } catch (error) {
      setLoading(false)
      setShowDeleteModal(false)
      setAlert({ open: true, severity: 'error', message: 'Something went wrong while deleting User, try again later' })
    }
  }

  const { errors, touched, values, handleBlur, handleSubmit, handleChange } = useFormik({
    initialValues: selectedUser,
    enableReinitialize: true,
    validationSchema: actionType === 'create' ? internalUserCreationFormSchema : internalUserUpdateFormSchema,
    onSubmit: vals => {
      setLoading(true)
      handleSaveUser(vals)
    }
  })

  useEffect(() => {
    getUsers()
  }, [])

  useEffect(() => {
    console.log('errors', errors)
    console.log('touched', touched)
  }, [errors])

  return (
    <>
      <h1>Internal Users</h1>
      <Box sx={{ margin: '2rem 0', display: 'flex', justifyContent: 'space-between' }}>
        <TextField size="small" placeholder="Search" />
        <Button variant="contained" color="primary" size="small" onClick={handleCreateUser}>
          Create user
        </Button>
      </Box>
      <Box sx={{ margin: '2rem 0' }}>
        <DataTable headers={headers} data={usersList} />
      </Box>
      <SideModal
        title={`${actionType === 'create' ? 'Create' : 'Update'} User`}
        open={showSideModal}
        loading={loading}
        onCancel={() => setShowSideModal(false)}
        onConfirm={handleSubmit}>
        {selectedUser && (
          <>
            <FormControl className={Styles.input}>
              <TextField
                name="name"
                label="Name"
                size="small"
                variant="outlined"
                value={values.name}
                error={Boolean(touched?.name && errors.name)}
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                name="last_name"
                label="Last Name"
                size="small"
                variant="outlined"
                value={values.last_name}
                error={Boolean(touched?.last_name && errors.last_name)}
                helperText={touched.last_name && errors.last_name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                name="email"
                label="Email"
                size="small"
                variant="outlined"
                value={values.email}
                error={Boolean(touched?.email && errors.email)}
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                name="phone"
                label="Phone"
                size="small"
                variant="outlined"
                value={values.phone}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                name="internal_user_role_id"
                label="Role"
                size="small"
                variant="outlined"
                select
                value={values.internal_user_role_id}
                onChange={handleChange}>
                {userRoles.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            {actionType === 'create' && (
              <>
                <Divider sx={{ marginBottom: '1.5rem' }} />
                <FormControl className={Styles.input}>
                  <TextField
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    size="small"
                    variant="outlined"
                    value={values.password}
                    error={Boolean(touched?.password && errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl className={Styles.input}>
                  <TextField
                    name="password_confirmation"
                    type={showPasswordConfirmation ? 'text' : 'password'}
                    label="Confirm Password"
                    size="small"
                    variant="outlined"
                    value={values.password_confirmation}
                    error={Boolean(touched?.password_confirmation && errors.password_confirmation)}
                    helperText={touched.password_confirmation && errors.password_confirmation}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end">
                            {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormControl>
              </>
            )}
          </>
        )}
      </SideModal>
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
      <DeleteModal
        element={selectedUser}
        open={showDeleteModal}
        loading={loading}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteUser}
      />
    </>
  )
}

export default InternalUsers
