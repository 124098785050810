export class Plan {
  id
  name
  slug
  description
  price
  stripe_plan
  modules
  created_at
  updated_at
  deleted_at

  constructor() {
    this.name = ''
    this.slug = ''
    this.description = ''
    this.price = ''
    this.stripe_plan = ''
    this.modules = []
    this.created_at = new Date()
    this.updated_at = new Date()
  }
}

export default Plan
