import './LoaderBackground.scss'

const LoaderBackground = () => {
  return (
    <div className="loader-background">
      <div className="box-of-star1">
        <div className="star star-position1" />
        <div className="star star-position2" />
        <div className="star star-position3" />
        <div className="star star-position4" />
        <div className="star star-position5" />
        <div className="star star-position6" />
        <div className="star star-position7" />
        <div className="star star-position8" />
        <div className="star star-position9" />
        <div className="star star-position10" />
      </div>
      <div className="box-of-star2">
        <div className="star star-position1" />
        <div className="star star-position2" />
        <div className="star star-position3" />
        <div className="star star-position4" />
        <div className="star star-position5" />
        <div className="star star-position6" />
        <div className="star star-position7" />
        <div className="star star-position8" />
        <div className="star star-position9" />
        <div className="star star-position10" />
      </div>
      <div className="box-of-star3">
        <div className="star star-position1" />
        <div className="star star-position2" />
        <div className="star star-position3" />
        <div className="star star-position4" />
        <div className="star star-position5" />
        <div className="star star-position6" />
        <div className="star star-position7" />
        <div className="star star-position8" />
        <div className="star star-position9" />
        <div className="star star-position10" />
      </div>
      <div className="box-of-star4">
        <div className="star star-position1" />
        <div className="star star-position2" />
        <div className="star star-position3" />
        <div className="star star-position4" />
        <div className="star star-position5" />
        <div className="star star-position6" />
        <div className="star star-position7" />
        <div className="star star-position8" />
        <div className="star star-position9" />
        <div className="star star-position10" />
      </div>
    </div>
  )
}

export default LoaderBackground
